import clsx from 'clsx';
import CodiEspectador from 'components/3cat/CodiEspectador';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import Icona from 'components/3cat/ui/Icona';
import LogoDirecte from 'components/3cat/ui/LogoDirecte';
import ProgressBar from 'components/3cat/ui/ProgressBar';
import __isEmpty from 'lodash/isEmpty';
import styles from './directe.horitzontal.module.scss';

const _getCanal = (canal) => {
    switch (canal) {
        case 'CAD_ES3':
            return 'esport3';
        case 'CAD_OCA1':
        case 'CAD_OCA2':
        case 'CAD_OCA3':
            return 'oca1';
        default:
            return canal;
    }
};
const _updateImatges = (e) => {
    const imatges = e?.ara_fem?.imatges;
    const destacat_imatge = e?.ara_fem?.destacat_imatge;

    if (!__isEmpty(imatges) && destacat_imatge) {
        return [...imatges, { text: destacat_imatge, mida: 'DESTACAT_IMATGE', rel_name: 'IMATGE_CUSTOM' }];
    } else if (destacat_imatge) {
        return [{ text: destacat_imatge, mida: 'DESTACAT_IMATGE', rel_name: 'IMATGE_CUSTOM' }];
    } else {
        return imatges;
    }
};
const _getCapitol = (e) => {
    let text = '';
    if (e.ara_fem?.tipus === 'radio') {
        if (e.name === 'cm' && e.ara_fem?.ara_sona?.ccmaarasona === 'SI')
            text =
                e.ara_fem?.ara_sona?.autor +
                (e.ara_fem?.ara_sona?.autor && e.ara_fem?.ara_sona?.fragment_obra ? ' - ' : '') +
                e.ara_fem?.ara_sona?.fragment_obra;
        else if (e.name === 'ic' && e.ara_fem?.ara_sona?.ccmaarasona === 'SI')
            text =
                e.ara_fem?.ara_sona?.titol +
                (e.ara_fem?.ara_sona?.titol && e.ara_fem?.ara_sona?.artista ? ' - ' : '') +
                e.ara_fem?.ara_sona?.artista;
        if (__isEmpty(text) && !__isEmpty(e.ara_fem.presentador)) {
            text = 'Amb ' + e.ara_fem.presentador;
        }
    } else {
        text = e.ara_fem?.titol_capitol || e.ara_fem?.capitols?.[0].desc;
    }
    return text;
};
const _getInfoVideo = (e, isFastChannel) => {
    if (isFastChannel) {
        const canal = e?.canals
            ?.map((canal) => {
                canal.main === true;
                return canal.id;
            })
            ?.toString();
        return {
            titol: e.avantitol,
            capitol: e.entradeta,
            link: canal,
            canal: canal,
            logo: canal,
            imatge: e.imatges,
            drets_cat: true,
            tipusDirecte: 'tv',
            logoShow: true
        };
    } else {
        const imatges = _updateImatges(e);
        return {
            titol: e.ara_fem?.titol_programa,
            capitol: _getCapitol(e),
            link: e.ara_fem?.codi_canal,
            canal: e.ara_fem?.codi_canal,
            logo: e.ara_fem?.logo,
            imatge: imatges,
            start_time: e.ara_fem?.start_time,
            end_time: e.ara_fem?.end_time,
            codi_etic: e.ara_fem?.codis_etics,
            drets_cat: e.ara_fem?.drets_cat === 'no' ? false : true,
            tipusDirecte: e.ara_fem?.tipus,
            logoShow: !e.ara_fem?.logoHide
        };
    }
};
export default function Directe(props) {
    const { data, estil, tipus, responsive, marcatgeAudienciesClick, isFastChannel } = props;

    const finalInfo = _getInfoVideo(data, isFastChannel);
    const { codi_etic, logo, imatge, start_time, end_time, tipusDirecte, logoShow } = finalInfo;
    let { canal } = finalInfo;
    let itemTitol = finalInfo.titol;
    const itemCapitol = finalInfo.capitol;
    const isRadio = finalInfo.tipusDirecte === 'radio' && canal != 'oca1';
    let disponible = data.ara_fem?.drets_cat !== 'no';

    if (__isEmpty(itemTitol) && __isEmpty(imatge) && __isEmpty(finalInfo.link)) {
        if (data.name == 'c33') {
            canal = 'c33';
            itemTitol = 'El canal emet de 21.30 a 05.59';
            disponible = false;
        } else {
            return <></>;
        }
    }

    const route = {
        id: canal === 'c33' ? '33' : canal.toLowerCase(),
        tipologia: 'DIRECTE',
        tipusDirecte
    };

    const tipusEstil = isFastChannel ? 'horitzontal' : tipus;
    const stylesItemMosaic = clsx({
        [styles.itemMosaic]: true,
        [styles.inactiu]: !disponible,
        [styles.responsive]: responsive,
        [styles[estil]]: true,
        [styles[tipusEstil]]: true,
        ['focusable']: true
    });

    const stylesImatge = clsx({
        [styles.imatge]: true,
        [styles.noDisponible]: !disponible
    });

    const isOca = canal?.startsWith('oca');
    let iconaLogoDirecte = false;
    let exclusiuInternet = false;
    if (isOca && logo.toUpperCase() === 'NO' && tipusDirecte === 'radio') {
        iconaLogoDirecte = 'cr';
        exclusiuInternet = true;
    }
    const stylesLogoDirecte = clsx({
        [styles[iconaLogoDirecte]]: iconaLogoDirecte,
        [styles.logoExclusiuInternet]: exclusiuInternet,
        [styles.logocanal]: true,
        [styles[_getCanal(canal)]]: true
    });

    const stylesWrapper = clsx({
        [styles.wrapperItemMosaic]: true,
        [styles.wrapperRadio]: isRadio
    });

    return (
        <div className={stylesItemMosaic} data-testid={`item-mosaic-${canal}`}>
            <div className={stylesWrapper}>
                <Enllac
                    route={route}
                    disponible={disponible}
                    className={styles.enllacImatge}
                    marcatgeAudienciesClick={marcatgeAudienciesClick}
                >
                    <div className={stylesImatge}>
                        <Imatge
                            imatges={imatge}
                            tipus='web_mosaic'
                            alt={itemTitol}
                            aria-hidden='true'
                            layout='fill'
                            objectFit='cover'
                            loading='lazy'
                            crop='555x312'
                            mostrarRecursTitol={false}
                        />
                        <div className={styles.bottomgradient}>
                            {logoShow && (
                                <LogoDirecte
                                    className={stylesLogoDirecte}
                                    canal={_getCanal(canal)}
                                    logo={logo}
                                    tipus={tipusDirecte}
                                />
                            )}
                        </div>
                        {disponible && (
                            <div className={styles.icona_play}>
                                <Icona icona='play' className={styles.play} alt='reproduir' mida={28} />
                            </div>
                        )}
                        {isRadio && (
                            <span className={styles.spanIcon}>
                                <Icona icona='auriculars_ple' className={styles.auricularsIcon} alt='icona auriculars' />
                            </span>
                        )}
                    </div>
                </Enllac>

                <Enllac
                    route={{ ...route, format: 'KEYFRAME' }}
                    disponible={disponible}
                    className={styles.info}
                    marcatgeAudienciesClick={marcatgeAudienciesClick}
                >
                    <div className={styles.cos}>
                        <div className={styles.descripcio + ' ' + styles.descripcioWebview}>
                            <div>
                                {itemTitol && <h2 className={styles.titol}>{itemTitol}</h2>}
                                {itemCapitol && <p className={styles.capitol}>{itemCapitol}</p>}
                            </div>
                            {disponible && (
                                <Icona icona='info_contorn' className={styles.iconaInfo} alt='Info' isBlanc={true} mida={22} />
                            )}
                        </div>
                        <div className={styles.codiDurada + ' ' + styles.isWebviewActive}>
                            {codi_etic && (
                                <CodiEspectador className={styles.codiespectador} codi={codi_etic} alt={codi_etic} mida={20} />
                            )}
                        </div>
                    </div>
                    <ProgressBar className={styles.progres} start_time={start_time} end_time={end_time} />
                </Enllac>
            </div>
        </div>
    );
}

Directe.defaultProps = {
    responsive: true
};
